import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { Image } from '../../../../components/atoms';
import Trademarked from '../../../../components/atoms/Trademarked';

const useStyles = makeStyles(() => ({
  image: {
    maxWidth: 420,
  },
}));

const WhoWeAre = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid
        container
        justify="space-between"
        spacing={isMd ? 4 : 2}
        direction={isMd ? 'row' : 'column-reverse'}
      >
        <Grid
          item
          container
          alignItems="center"
          justify="flex-start"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <SectionHeader
            title={(<>{'This is '}<Trademarked>Formigio</Trademarked>{' by Never Behind'}</>)}
            disableGutter
            align="left"
            titleProps={{
              component: 'h2',
              color: 'primary'
            }}
          />
          <Typography component="p" className={classes.text}>
            The <Trademarked>Formigio</Trademarked> apps are made by people that love to get stuff done. Over the years we have had some talented people help develop the different apps and data services.
            The planner application is a web-based online software application that is made by people that love to get stuff done.
          </Typography>
          <Typography component="p" className={classes.text}>
            The online application is available on any modern browser, and can be used after creating a free account. There are different subscriptions available depending on how much you want to use the software to get things done.
          </Typography>
          <Typography component="p" className={classes.text}>
            Geoff Douglas is the founder and creator of the concepts that drive the apps. He has done much of the programming with help from some senior level javascript developers and even some developers just starting out.
          </Typography>
        </Grid>
        <Grid
          item
          container
          justify={isMd ? 'flex-end' : 'flex-start'}
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <Image
            src="https://assets.maccarianagency.com/the-front/illustrations/working-on-sofa.svg"
            alt="Our story"
            className={classes.image}
          />
        </Grid>
      </Grid>
    </div>
  );
};

WhoWeAre.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default WhoWeAre;
